import PropTypes from 'prop-types'
import {If, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash'
import media from '../../utils/media'
import Link from '../Link'


const StyledBackground = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
  background-image:
    linear-gradient(${({theme}) => theme.overlays.gradient}),
    url(${({desktopBackground}) => desktopBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  ${media.down('mobile')(css`
    background-image:
      linear-gradient(${({theme}) => theme.overlays.gradient}),
      url(${({mobileBackground}) => mobileBackground});
    background-attachment: fixed;
    padding: 6rem 2.25rem;
    min-height: 100vh;

    ${({visible}) => visible && css`
      background-image:
        linear-gradient(${({theme}) => theme.overlays.cookieBarGradient}),
        url(${({mobileBackground}) => mobileBackground});
      padding-bottom: 26rem;
    `}

    ${({visibleOptions}) => visibleOptions && css`
      background-image:
        linear-gradient(${({theme}) => theme.overlays.cookieBarGradient}),
        url(${({mobileBackground}) => mobileBackground});
      padding-bottom: 37rem;
    `}
  `)}
`

const StyledLogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1rem;

  ${media.down('mobile')(css`
    display: unset;
  `)}
`

const StyledImageContainer = styled.div`
  max-width: 15rem;
  padding: 0 0 1rem 2.5rem;

  ${media.down('mobile')(css`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 0 1.25rem 0;
  `)}

  img {
    max-width: 100%;
    height: auto;
  }
`

const StyledBackgroundFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 5rem;
`

const Background = ({linkText, logo, cookiesLink, onCookiesEdit, to, desktopBackground, mobileBackground, visible,
  visibleOptions, gdpr, children, ...props}) => {

  return (
    <StyledBackground
        desktopBackground={desktopBackground}
        mobileBackground={mobileBackground}
        visible={visible}
        visibleOptions={visibleOptions}
        {...props}
    >
      <Choose>
        <When condition={!isEmpty(logo)}>
          <StyledLogoWrapper>
            <StyledImageContainer>
              {logo}
            </StyledImageContainer>
            {children}
          </StyledLogoWrapper>
        </When>
        <Otherwise>
          {children}
        </Otherwise>
      </Choose>
      <If condition={gdpr}>
        <StyledBackgroundFooter>
          <If condition={linkText}>
            <Link to={to}>{linkText}</Link>
          </If>
          <If condition={cookiesLink}>
            <Link onClick={onCookiesEdit}>{cookiesLink}</Link>
          </If>
        </StyledBackgroundFooter>
      </If>
    </StyledBackground>
  )
}

Background.propTypes = {
  linkText: PropTypes.string,
  logo: PropTypes.node,
  cookiesLink: PropTypes.string,
  to: PropTypes.string,
  desktopBackground: PropTypes.string,
  mobileBackground: PropTypes.string,
  visible: PropTypes.bool,
  visibleOptions: PropTypes.bool,
  onCookiesEdit: PropTypes.func.isRequired,
  gdpr: PropTypes.bool,
  children: PropTypes.node,
}

export default Background
