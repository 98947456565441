import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import media from '../../utils/media'
import convertMDContent from '../../utils/convertMDContent'
import Text from '../../visual/typography/Text'
import Button from '../../visual/Button'
import {t} from '../../translations'
import * as typography from '../typography/typography'
import Checkbox from '../form/Checkbox'


const StyledBar = styled.div`
  position: fixed;
  top: 100%;
  transform: ${({isVisible}) => isVisible ? 'translateY(-100%)' : 'translateY(0)'};
  transition: transform 0.3s ease-in;
  z-index: 9999;
  background-color: ${({theme}) => theme.overlays.backgroundNoBlurCookieBar};
  padding: 2rem 6.5rem;
  width: 100%;
  max-width: 100vw;
  color: ${({theme}) => theme.colors.textInverted};

  @supports (backdrop-filter: ${(({theme}) => theme.backdropBlur)}) {
    background-color: ${({theme}) => theme.overlays.background};
    backdrop-filter: ${(({theme}) => theme.backdropBlur)};
  }

  ${media.down('mobile')(css`
    flex-flow: column;
    margin-right: 0;
    padding: 1.25rem 2.5rem;
    text-align: center;
  `)}
`

const StyledTitle = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  ${typography.h6};
  font-weight: bold;
`

const StyledText = styled(Text)`
  display: flex;
  justify-content: center;
  ${typography.h6};

  p {
    padding: ${({theme}) => theme.spacer} 0 0;
    max-width: 60rem;

    a {
      cursor: auto;
      color: ${({theme}) => theme.colors.textInverted};
      text-decoration: none;
    }

    ${media.down('mobile')(css`
      width: 100%;
      padding: calc(${({theme}) => theme.spacer} * 2.5) 0 ${({theme}) => theme.spacer};
    `)}
  }
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: start;
    width: max-content;
    margin: 0 auto;
  `)}
`

const StyledCheckbox = styled(Checkbox)`
  &:not(:last-child) {
    margin-right: calc(${({theme}) => theme.spacer} * 6);
  }

  ${media.down('mobile')(css`
    &:not(:last-child) {
      margin-right: 0;
    }

    margin-bottom: 1.5rem;
  `)}

  a {
    padding-left: 2rem;
    white-space: pre;
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: calc(${({theme}) => theme.spacer} * 2.5) 0 0;

  button {
    &:not(:last-child) {
      margin-right: calc(${({theme}) => theme.spacer} * 3.5);
    }
  }

  ${media.down('mobile')(css`
    flex-wrap: wrap;
    justify-content: center;

    button {
      width: min-content;
      margin: 0 0 calc(${({theme}) => theme.spacer} * 1.5) 0;

      &:not(:last-child) {
        margin-right: calc(${({theme}) => theme.spacer} * 1.5);
      }
    }
  `)}
`

const StyledButton = styled(Button)`
  cursor: pointer;
  padding: 1.25rem 2.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
`

const CookieBar = ({
  lang, visible, selectedCookies, visibleOptions, onAllow, onReject, onAllowSelected, onOpenOptions,
  onSelectedChange, ...props
}) => {
  return (
    <StyledBar isVisible={visible} {...props}>
      <StyledTitle variant="h2" type="h6">{t(lang, 'cookies.title')}</StyledTitle>
      <div>
        <StyledText
            component="div"
            color="textInverted"
            dangerouslySetInnerHTML={{__html: convertMDContent(t(lang, 'cookies.content'))}}
        />
        <If condition={visibleOptions}>
          <StyledCheckboxContainer>
            <StyledCheckbox label={t(lang, 'cookies.technicalCookies')} checked />
            <StyledCheckbox
                name="analyticalCookies"
                label={t(lang, 'cookies.analyticalCookies')}
                value={selectedCookies?.analyticalCookies}
                checked={selectedCookies?.analyticalCookies}
                onChange={onSelectedChange}
            />
            <StyledCheckbox
                name="marketingCookies"
                label={t(lang, 'cookies.marketingCookies')}
                value={selectedCookies?.marketingCookies}
                checked={selectedCookies?.marketingCookies}
                onChange={onSelectedChange}
            />
          </StyledCheckboxContainer>
        </If>
        <StyledButtonsContainer>
          <StyledButton inverted onClick={onReject}>{t(lang, 'cookies.reject')}</StyledButton>
          <If condition={!visibleOptions}>
            <StyledButton inverted onClick={onOpenOptions}>{t(lang, 'cookies.options')}</StyledButton>
          </If>
          <If condition={visibleOptions}>
            <StyledButton inverted onClick={onAllowSelected}>{t(lang, 'cookies.allowSelected')}</StyledButton>
          </If>
          <StyledButton onClick={onAllow}>{t(lang, 'cookies.allowAll')}</StyledButton>
        </StyledButtonsContainer>
      </div>
    </StyledBar>
  )
}

CookieBar.propTypes = {
  lang: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  selectedCookies: PropTypes.object,
  visibleOptions: PropTypes.bool,
  onAllow: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onAllowSelected: PropTypes.func.isRequired,
  onOpenOptions: PropTypes.func.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
}

export default CookieBar
