import {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import CookieBar from '../../visual/components/CookieBar'
import Background from '../../visual/components/Background'
import {INITIAL_CONSENT, useConsent} from '../../hooks/useConsent'
import {t} from '../../translations'
import Seo from '../Seo'


const AppShell = ({
  title, lang, gdpr, seo, meta, linkGdpr, mobileBackground, desktopBackground, logo, children, ...props
}) => {
  const [visibleOptions, setVisibleOptions] = useState(false)
  const {preferences, consent} = useConsent()
  const [visible, setVisible] = useState(!preferences)
  const [selectedCookies, setSelected] = useState()

  useEffect(() => {
    setSelected(preferences || INITIAL_CONSENT)
  }, [preferences])

  const handleSelectedChange = (e) => {
    const {name, checked} = e.target

    setSelected((prevState) => ({...prevState, [name]: checked}))
  }

  const handleOpenOptions = () => {
    setVisibleOptions(true)
  }

  const handleCookiesEdit = () => {
    setVisible(true)
  }

  const handleAllow = () => {
    consent({
      analyticalCookies: true,
      marketingCookies: true,
    })
    setVisible(false)
    setVisibleOptions(false)
  }

  const handleAllowSelected = () => {
    consent(selectedCookies)
    setVisible(false)
    setVisibleOptions(false)
  }

  const handleReject = () => {
    consent({analyticalCookies: false, marketingCookies: false})
    setVisible(false)
    setVisibleOptions(false)
  }

  return (
    <>
      <Seo title={title} seo={seo} lang={lang} meta={meta} {...props} />
      <main>
        <Background
            to={linkGdpr}
            linkText={t(lang, 'cookies.termsAndConditions')}
            cookiesLink={t(lang, 'cookies.settings')}
            mobileBackground={mobileBackground}
            desktopBackground={desktopBackground}
            logo={logo}
            onCookiesEdit={handleCookiesEdit}
            visible={visible}
            visibleOptions={visibleOptions}
            gdpr={gdpr}
        >
          {children}
        </Background>
      </main>
      <If condition={visible}>
        <CookieBar
            lang={lang}
            visible={visible}
            selectedCookies={selectedCookies}
            visibleOptions={visibleOptions}
            onAllow={handleAllow}
            onAllowSelected={handleAllowSelected}
            onReject={handleReject}
            onOpenOptions={handleOpenOptions}
            onSelectedChange={handleSelectedChange}
        />
      </If>
    </>
  )
}

AppShell.defaultProps = {
  seo: {
    title: '',
    metaDescription: '',
    SharedImage: {},
  },
}

AppShell.propTypes = {
  title: PropTypes.string,
  lang: PropTypes.string.isRequired,
  gdpr: PropTypes.bool,
  seo: PropTypes.shape({
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    SharedImage: PropTypes.object,
  }),
  meta: PropTypes.arrayOf(PropTypes.object),
  linkGdpr: PropTypes.string,
  logo: PropTypes.node,
  mobileBackground: PropTypes.string,
  desktopBackground: PropTypes.string,
  children: PropTypes.node,
}

export default AppShell
