import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {SK} from '../translations'


const Seo = ({title, seo, lang, meta}) => {
  return (
    <Helmet
        async
        title={title}
        htmlAttributes={{
          lang: lang || SK,
        }}
        meta={[
          {
            name: 'description',
            content: seo?.metaDescription,
          },
          {
            property: 'og:title',
            content: seo?.metaTitle || '',
          },
          {
            property: 'og:description',
            content: seo?.metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: seo?.SharedImage?.media?.localFile?.publicURL || '',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: '',
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: seo?.metaDescription,
          },
          {
            name: 'twitter:image',
            content: seo?.SharedImage?.media?.localFile?.publicURL || '',
          },
        ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
}

Seo.propTypes = {
  title: PropTypes.string,
  seo: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    SharedImage: PropTypes.object,
  }),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Seo
