import showdown from 'showdown'


const convertMDContent = (content) => {
  const converter = new showdown.Converter({noHeaderId: true})
  const convertedContent = converter.makeHtml(content)

  return convertedContent
}

export default convertMDContent
