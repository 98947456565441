import {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {readableColor} from 'polished'
import media from '../../utils/media'
import CheckboxIcon from '../../assets/icons/checkbox.svg'
import * as typography from '../typography/typography'
import Text from '../typography/Text'


const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
`

const StyledCheckbox = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `border ${theme.animation}`};
  border: 1px solid ${({theme}) => theme.colors.textInverted};
  border-radius: ${({theme}) => theme.border.radius.sm};
  width: 1.75rem;
  height: 1.75rem;

  svg {
    transform: translateX(40%);
    opacity: 0;
    fill: ${({theme}) => readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text)};
    path, g {
      fill: ${({theme}) => readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text)};
    }

    ${media.down('mobile')(css`
      transform: translateX(25%);
    `)}
  }
`

const StyledInput = styled.input`
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;

  &:checked + ${StyledCheckbox} {
    border: 1px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.primary};

    svg {
      opacity: 1;
    }
  }
`

const StyledLabelText = styled.span`
  margin-left: 1.5rem;
  text-align: left;
  color: ${({theme}) => theme.colors.textInverted};
  ${typography.h6};

  & > a {
    color: ${({theme}) => theme.colors.textInverted};
  }
`

const StyledText = styled(Text)`
  padding-left: 0.25rem;
`

const ConsentCheckbox = forwardRef(({name, value, label, checked, required, onChange, ...props},
  ref) => {
  return (
    <StyledLabel checked={checked} {...props}>
      <StyledInput
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          checked={checked}
          ref={ref}
      />
      <StyledCheckbox>
        <CheckboxIcon />
      </StyledCheckbox>
      <If condition={label}>
        <StyledLabelText>
          {label}
        </StyledLabelText>
        <If condition={required}>
          <StyledText component="span" color="negative">*&nbsp;</StyledText>
        </If>
      </If>
    </StyledLabel>
  )
})

ConsentCheckbox.displayName = 'ConsentCheckbox'

ConsentCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
}

export default ConsentCheckbox
