import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as typography from './typography/typography'


const StyledLink = styled.a`
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: ${({theme}) => theme.colors.secondary};
  ${typography.link};
`

const Link = ({to, children, ...props}) => {
  return (
    <StyledLink href={to} {...props}>{children}</StyledLink>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}

export default Link
