import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import {keys} from 'lodash'
import {readableColor} from 'polished'
import media from '../utils/media'
import * as typography from './typography/typography'
import Link from './Link'


const applyVariant = {
  default: css`
    padding: 1em 4.25em;
  `,
  plain: css`
    background: transparent;
    padding: 0;
  `,
}

const StyledButton = styled.button`
  display: flex;
  border: none;
  border-radius: ${({theme}) => theme.border.radius.md};
  background-color: ${({theme, inverted}) => inverted ? theme.colors.secondary2 : theme.colors.primary};
  margin-top: ${({theme}) => `calc(${theme.spacer} * 0.75)`};
  width: min-content;
  cursor: pointer;
  color: ${({theme, inverted}) => inverted
    ? theme.colors.textInverted
    : readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text)};
  white-space: pre;

  svg {
    fill: ${({theme, inverted}) => (
    inverted
      ? theme.colors.textInverted
      : readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text))};

    path, g {
      fill: ${({theme, inverted}) => (
    inverted
      ? theme.colors.textInverted
      : readableColor(theme.colors.primary, theme.colors.textInverted, theme.colors.text))};
    }
  }

  ${typography.button};
  ${({variant}) => applyVariant[variant]}
  ${({icon}) => icon && css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 3.75em;
  `}

  ${media.down('mobile')(css`
    width: 100%;
    white-space: pre-wrap;

    svg {
      min-width: 20px;
    }
  `)}
`

const StyledSpan = styled.span`
  ${({icon, variant}) => !icon && variant === 'filled' && css`
    margin: 0 auto;
  `}

  ${({icon}) => icon && css`
    padding-left: ${({theme}) => theme.spacer};
  `}
`

const Button = ({to, onClick, icon, inverted, children, ...props}) => {
  const as = to ? Link : 'button'

  return (
    <StyledButton inverted={inverted} as={as} to={to} onClick={onClick} icon={icon} {...props}>
      <If condition={icon}>
        {icon}
      </If>
      <StyledSpan icon={icon}>
        {children}
      </StyledSpan>
    </StyledButton>
  )
}

Button.defaultProps = {
  variant: 'default',
}

Button.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  inverted: PropTypes.bool,
  variant: PropTypes.oneOf(keys(applyVariant)),
  children: PropTypes.node,
}

export default Button
