import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import theme from '../../theme/theme'
import media from '../../utils/media'
import * as typography from './typography'


// Explicitly list for webpack
const styles = {
  body: typography.body,
  h1: typography.h1,
  h2: typography.h2,
  h3: typography.h3,
  h4: typography.h4,
  h5: typography.h5,
  h6: typography.h6,
  button: typography.button,
  link: typography.link,
  input: typography.input,
}

const StyledSpan = styled.span`
  ${({align}) => align && css`
    text-align: ${align};
  `}
  ${({width}) => width && css`
    width: ${width};
  `}
  ${({textStyle}) => textStyle && styles[textStyle]}
  ${({theme, color}) => color && css`
    color: ${theme.colors[color]};
  `}

  ${media.down('mobile')(css`
    width: auto;
  `)}
`

const Text = ({component, textStyle, color, align, width, ...props}) => {
  return (
    <StyledSpan
        as={component}
        textStyle={textStyle}
        color={color}
        align={align}
        width={width}
        {...props}
    />
  )
}

Text.defaultProps = {
  component: 'span',
  textStyle: 'body',
  align: 'left',
  width: 'auto',
}

Text.propTypes = {
  component: PropTypes.elementType,
  textStyle: PropTypes.oneOf(keys(styles)),
  color: PropTypes.oneOf(keys(theme.colors)),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  width: PropTypes.string,
}

export default Text
