import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../../utils/media'


const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  background-color: ${({theme}) => theme.overlays.backgroundNoBlur};
  border-radius: ${({theme}) => theme.border.radius.md};
  padding: 2.75rem 1.25rem 1.25rem 1.25rem;
  max-width: 60rem;
  margin-bottom: ${({theme}) => `calc(${theme.spacer} * 1.75)`};

  @supports (backdrop-filter: ${(({theme}) => theme.backdropBlur)}) {
    background-color: ${({theme}) => theme.overlays.background};
    backdrop-filter: ${(({theme}) => theme.backdropBlur)};
  }

  ${media.down('mobile')(css`
    width: 100%;
    padding: 2.375rem 2.125rem 1.375rem 1.5rem;
  `)}
`

const ContentContainer = ({children, ...props}) => {
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node,
}

export default ContentContainer
